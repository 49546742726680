<template>
  <fragment>
    <div class="container home--header">
      <button v-if="showHomeBtn" class="home-btn" @click="backBtnClickHandler">
          <div>
            <b-icon
              pack="fas"
              icon="arrow-left"
              size="is-small">
            </b-icon>
            <p>
              Home
            </p>
          </div>
        </button>
    </div>
    <router-view />
  </fragment>
</template>

<script>
import { Fragment } from 'vue-fragment'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'PlanRoutes',
  components: {
    Fragment
  },
  data () {
    return {
      showHomeBtn: this.$route.path !== '/plans'
    }
  },
  mounted () {
    const backButtonRouteGuard = this.$router.beforeEach((to, from, next) => {
      const unsavedWork = (from.name === 'Notification setup' && this.getHasNotificationConfigChange)

      if (unsavedWork) {
        next(false)
        this.updateShowWarningModalLocal(to.path)
      } else {
        next(true)
      }
    })

    this.$once('hook:destroyed', () => {
      backButtonRouteGuard()
    })
  },
  computed: {
    ...mapGetters(['getHasNotificationConfigChange'])
  },
  methods: {
    ...mapActions(['updateShowWarningModal']),
    updateShowWarningModalLocal (path = true) {
      this.updateShowWarningModal(path)
    },
    backBtnClickHandler () {
      !this.getHasNotificationConfigChange ? this.goHome() : this.updateShowWarningModal(true)
    },
    onResize () {
      this.isDesktop = window.innerWidth >= 1024
    },
    goHome () {
      this.$router.push({ path: '/plans' })
    }
  },
  watch: {
    $route: function () {
      this.showHomeBtn = this.$route.path !== '/plans'
    }
  }
}
</script>

<style lang="scss" scoped>
.home-btn {
  border: none;
  background: none;
  color: #868686;
  cursor: pointer;
  position: absolute;
  left: 0;
  padding: 0;
  margin-top: 10px;
  transition: color .2s;
  z-index: 10;

  &:hover {
    color: #545454;
    transition: color .2s;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      position: relative;
      bottom: 1px;
      font-size: 14px;
    }
  }

  .icon {
    margin-right: 5px;
  }
}
</style>
